import './App.css';
import Header from './Header';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./Footer";
import HomePage from "./HomePage"
import AboutPage from "./AboutPage";
import TestPage from "./TestPage";
import ScrollToTop from "./ScrollToTop";
function App() {
  return (
    <Router>
       <ScrollToTop /> {/* ScrollToTop 추가 */}
      <Header/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/test" element={<TestPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
