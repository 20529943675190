import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css"; // 스타일링을 위한 CSS 파일

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <Link to="/" className="logo-container">
          <div className="logo">
            <img src="logo.svg" alt="Logo" />
          </div>
        </Link>
        <nav className="nav-menu">
          <Link to="/about" className="nav-item">
          Learn About Igloo
          </Link>
          <a href="/test/index.html" className="nav-item btn-test">
          Learning Style Test
          </a>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
