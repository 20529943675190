import React, {useEffect, useState} from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.css"; // 스타일링을 위한 CSS 파일


const Header = () => {
    const [scrolled, setScrolled] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
          const offset = window.scrollY;
          setScrolled(offset > 50); // 스크롤이 50px 이상 내려가면 상태를 true로 설정
        };
    
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);
  return (
    
    <header className={`header ${scrolled ? "scrolled" : ""}`}>
      <div className="header-container">
        <Link to="/" className="logo-container">
          <div className="logo">
            <img src="/logo.svg" alt="Logo" />
          </div>
        </Link>
        <nav className="nav-menu">
          <Link to="/about" className="nav-item">
          Learn About Igloo
          </Link>
          <a href="/test/index.html" className="nav-item btn-test">
          Learning Style Test
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
