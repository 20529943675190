import React from "react";
import { Link } from "react-router-dom";
import "./TestPage.css"; // 스타일링을 위한 CSS 파일

const TestPage = () => {
  return (
    <header className="test">
      

    </header>
  );
};

export default TestPage;
