import React from "react";
import { Link } from "react-router-dom";
import "./HomePage.css"; // 스타일링을 위한 CSS 파일
import bg from "./background.jpg";

const HomePage = () => {
  return (
    <>
    <div className="bg-hero" style={{backgroundImage: `url(${bg})`}}>
        <div className="container hero">
            <div>
                <h1>Find Your<br></br> <span>Learning</span> Style!</h1>
                <p>Take our quick quiz to discover which of the 6 animals matches your learning style. Unlock personalized insights and connect with study mates who learn like you. </p>
                <a href="/test/index.html">Take the Learning Style Test</a>
            </div>
            <img src="/phone.png"></img>
    </div>
    </div>
    <div className="bgcolor-sky"> 
        <div className="container animals" >
            <h2>What is your ideal<br></br> learning style?</h2>
            <ul>
                <li><img src="/whale.png"></img></li>
                <li><img src="/fox.png"></img></li>
                <li><img src="owl.png"></img></li>
            </ul>
        </div>
    </div>
    <div className="container results">
        <p>6 unique results</p>
        <h2>Find out which of the 6 animals matches your learning style!</h2>
        <ul>
            <li><img src="/1.png"></img></li>
            <li><img src="/2.png"></img></li>
            <li><img src="/3.png"></img></li>
            <li><img src="/4.png"></img></li>
            <li><img src="/5.png"></img></li>
            <li><img src="/6.png"></img></li>
        </ul>
    </div>
    <div className="bgcolor-sky">
        <div className="container igloo-app">
            <div>
                <p className="title-small">Igloo App</p>
                <h2>Find Your Study Mate</h2>
                <p>The Igloo app is launching soon!<br></br>Connect with study partners who share your learning style & collaborate anytime.</p>
                <a href="/about">Learn More</a>
            </div>
            <img src="/pic-mate.png"></img>
        </div>
    </div>
    <div className="last-cta">
        <h4>Test to Find <br/>Your Learning Style Now!</h4>
        <a href="/test/index.html">Take the Test</a>
    </div>
    </>
  );
};

export default HomePage;
