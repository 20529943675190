import React from "react";
import { Link } from "react-router-dom";
import "./AboutPage.css"; // 스타일링을 위한 CSS 파일
import bg2 from "./background2.jpg";

const AboutPage = () => {
  return (
    <div className="about">
      <div className="about-bg-hero" style={{backgroundImage: `url(${bg2})`}}>
          <div className="container2 hero">
              <div>
                  <h1><span>Virtual</span><br></br>Learning Space</h1>
                  <p>Igloo is an app designed to <b>connect students with similar learning styles</b> for online group study sessions, offering them study materials, tools, discussion support, guides, and more. </p>
                  <a href="#email-input">Go to early Sign-Up</a>
              </div>
              <img src="/phone2.png"></img>
        </div>
      </div>
      <div className="bgcolor-sky">
      <div className="break-sec container">
        <img src="logo.png"/>
        <h3>Study Hard</h3>
        <p>Maximize your focus.</p>
      </div>
      </div>
      <div className="container img1">
        <img src="/img1.png"/>
        <div>
          <h2>Learning Style Diagnosis</h2>
            <p>In just a few steps, discover your unique learning style. Complete a quick survey and instantly receive personalized insights to enhance your learning experience. </p>
        </div>
      </div>
      <div className="container img2">
        <img src="/img2.png"/>
        <div>
            <h2>Matchmaking System</h2>
            <p>Igloo's smart matchmaking system is driven by your survey results, pairing you with the perfect study partner in your personalized study space. Whether you need focused collaboration or shared motivation, we connect you with the ideal match to elevate your online study sessions. </p>
        </div>
      </div>
      <div className="container img1 img3">
        <img src="/img3.png"/>
        <div>
          <h2>Igloo Study Spaces</h2>
          <p>Igloo study spaces come equipped with a range of integrated tools, including flashcards and shared notes, to enhance your study sessions and collaboration.</p>
        </div>
      </div>
      <div className="bgcolor-sky">
      <div className="container email-cta" id="email-input">
        <h4>Coming Soon</h4>
        <h2>Enter your email address to stay tuned for our launch!</h2>
        <p>Early sign-up benefit: Get the first 6 months free</p>
        <div className="email-input">
          <input type="email" placeholder="Email"/>
          <button>Submit</button>
        </div>
      </div>
      </div>
    </div>
  );
};

export default AboutPage;
